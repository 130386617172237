<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  data() {
    return {}
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Good Bev Co.",
    // all titles will be injected into this template
    titleTemplate: "%s | Good Bev Co.",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Good Beverage Co. 21+ RTD Cocktails",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>

<style scoped>
#app {
  overflow-x: hidden;
}
</style>
