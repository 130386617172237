const routes = [
    {
        path: '/admin',
        component: () => import('../apps/AdminApp.vue'),
        children: [
            {
                path: '',
                redirect: { name: 'adminOrdersList' }
            }
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: '/admin',
        component: () => import('../apps/AdminApp.vue'),
        children: [
            {
                path: 'order',
                name: 'adminOrdersList',
                component: () => import('../views/admin/AdminOrdersView.vue'),
            },
            {
                path: 'order/create',
                name: 'adminOrderCreate',
                component: () => import('../views/admin/AdminOrderCreateView.vue'),
            },
            {
                path: 'order/:id',
                name: 'adminOrderView',
                component: () => import('../views/admin/AdminOrderView.vue'),
            },
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: '/admin',
        component: () => import('../apps/AdminApp.vue'),
        children: [
            {
                path: 'store',
                name: 'adminStoresList',
                component: () => import('../views/admin/AdminStoresView.vue'),
            },
            {
                path: 'store/create',
                name: 'adminStoreCreate',
                component: () => import('../views/admin/AdminStoreCreateView.vue'),
            },
            {
                path: 'store/:id',
                name: 'adminStoreView',
                component: () => import('../views/admin/AdminStoreView.vue'),
            },
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: '/admin',
        component: () => import('../apps/AdminApp.vue'),
        children: [
            {
                path: 'user',
                name: 'adminUsersList',
                component: () => import('../views/admin/AdminUsersView.vue'),
            },
            {
                path: 'user/:id',
                name: 'adminUserView',
                component: () => import('../views/admin/AdminUserView.vue'),
            },
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },

]

export default routes
