import axios from 'axios'

export default {
    namespaced: true,
    state: {
        user: {},
    },
    mutations: {
        setUser(state, newUser) {
            state.user = newUser
        }
    },
    actions: {
        fetchMe(store) {
            axios.get("/api/user").then((response) => {
                store.commit('setUser', response.data)
            });
        },
    }
}
