const routes = [
    {
        path: '/retailer',
        component: () => import('../apps/RetailerApp.vue'),
        children: [
            {
                path: '',
                redirect: { name: 'retailerOrdersList' }
            }
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: '/retailer',
        component: () => import('../apps/RetailerApp.vue'),
        children: [
            {
                path: 'order',
                name: 'retailerOrdersList',
                component: () => import('../views/retailer/RetailerOrdersView.vue'),
            },
            {
                path: 'order/create',
                name: 'retailerOrderCreate',
                component: () => import('../views/retailer/RetailerOrderCreateView.vue'),
            },
            {
                path: 'order/:id',
                name: 'retailerOrderView',
                component: () => import('../views/retailer/RetailerOrderView.vue'),
            },
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: '/retailer',
        component: () => import('../apps/RetailerApp.vue'),
        children: [
            {
                path: 'user',
                name: 'retailerUserView',
                component: () => import('../views/retailer/RetailerUserView.vue'),
            },
        ],
        meta: { sitemap: { ignoreRoute: true } }
    },

]

export default routes
