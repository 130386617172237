import Vue from 'vue'
import Router from 'vue-router'
import {publicRoutes} from './public.js'

import adminRoutes from './admin'
import retailerRoutes from './retailer'

const routes = adminRoutes.concat(publicRoutes).concat(retailerRoutes)

Vue.use(Router)

export default new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes,
})
