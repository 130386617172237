export const publicRoutes = [
  {
    path: '/',
    component: () => import('../apps/MainApp.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/MainView.vue'),
      }
    ]
  },
  {
    path: '/',
    component: () => import('../apps/MainApp.vue'),
    children: [
      {
        path: '/products',
        name: 'products',
        component: () => import('../views/ProductListView.vue'),
      }
    ]
  },
  {
    path: '/',
    component: () => import('../apps/MainApp.vue'),
    children: [
      {
        path: '/find',
        name: 'find',
        component: () => import('../views/FindUsView.vue'),
      }
    ]
  },
  {
    path: '/',
    component: () => import('../apps/MainApp.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
      }
    ],
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/',
    component: () => import('../apps/MainApp.vue'),
    children: [
      {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/SignupView.vue'),
      }
    ],
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '*',
    redirect: { name: 'home' },
  },
]
