export default {
  namespaced: true,
  state: {
    banner: {
      open: false,
      text: '',
      color: 'primary',
      textColor: 'black--text',
      dismissable: true,
      timeout: 5000,
    },
  },
  mutations: {
    setBanner(state, newBanner) {
      state.banner = newBanner
    }
  },
  actions: {
    open(store, banner) {
      banner.open = true
      if (!banner.color) {
        banner.color = 'primary'
      }
      if (!banner.textColor) {
        banner.textColor = 'white--text'
      }
      if (banner.timeout == null) {
        banner.timeout = 5000
      }
      store.commit('setBanner', banner)
      window.scrollTo(0,0);
    },
    error(store, banner) {
      banner.open = true
      if (!banner.color) {
        banner.color = 'red'
      }
      if (!banner.textColor) {
        banner.textColor = 'white--text'
      }
      if (banner.timeout == null) {
        banner.timeout = 5000
      }
      if (banner.dismissable == null) {
        banner.dismissable = true
      }
      store.commit('setBanner', banner)
      window.scrollTo(0,0);
    },
    close(store) {
      store.commit('setBanner', { open: false })
    }
  }
}
