import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
import './assets/css/global.css'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-43Q80YRHHN" }
}, router)

Vue.use(VueMeta)
Vue.use(VueCookies)

Vue.mixin({
  methods: {
    getAssetImg: function (pic) {
      return 'https://res.cloudinary.com/htsteu3bn/image/upload/' + pic
    },
    getAssetVid: function (pic) {
      return 'https://res.cloudinary.com/htsteu3bn/video/upload/' + pic
    },
  },
})


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
